const investorEducationList = [
  {
    title: "Blogs",
    link: `${process.env.NEXT_PUBLIC_MOTILAL_MF_MODULE_URL}investor-education/category/blog/`
  },
  {
    title: "Videos",
    link: `${process.env.NEXT_PUBLIC_MOTILAL_MF_MODULE_URL}investor-education/category/videos/`
  },
  {
    title: "Podcast",
    link: `${process.env.NEXT_PUBLIC_MOTILAL_MF_MODULE_URL}investor-education/category/podcast/`
  },
  {
    title: "Press Release",
    link: "/Media-Room/Press-Release"
  },
  {
    title: "Interviews",
    link: "/Media-Room/Press-Release"
  },
  {
    title: "News",
    link: "/Media-Room/Press-Release"
  }
]

const importantLinks = [
  {
    title: "Downloads",
    link: "/download/financials",
    isImportantLink: true
  },
  {
    title: "Account Statement",
    link: `${process.env.NEXT_PUBLIC_MOTILAL_MF_MODULE_URL}reporthub`,
    isImportantLink: true,
    external: true
  },
  {
    title: "Contact Us",
    link: "/contact-us",
    isImportantLink: true
  }
]

const distributorServices = [
  {
    title: "Login",
    link: `${process.env.NEXT_PUBLIC_PARTNER_MODULE_URL}investonline/dist`,
    image: "/assets/images/header/login.svg",
    external: true,
  },
  {
    title: "Downloads",
    link: "/download/financials",
    image: "/assets/images/header/downloads.svg"
  },
  {
    title: "Onboard New Client",
    link: "https://digiaif.motilaloswalamc.com/login",
    image: "/assets/images/header/onboard-new-client.svg",
    external: true,
  },
  {
    title: "Why Choose Us",
    link: process.env.NEXT_PUBLIC_PARTNER_MODULE_URL,
    image: "/assets/images/header/why-choose-us.svg",
    external: true,
  },
]

const mobileHeaderLinks = {
  "Investor Services": [
    ...investorEducationList,
    {
      title: "Important Links",
      isHeading: true
    },
    ...importantLinks,
  ],
  "Partner Services": [
    ...distributorServices
  ],
}

export {
  investorEducationList,
  importantLinks,
  distributorServices,
  mobileHeaderLinks
}