import CryptoJS from 'crypto-js';

const registerAesKeyOnServer = () => {
    let randomBadAss = Math.floor(1000000000000000 + Math.random() * 9000000000000000).toString();
    return randomBadAss.toString();
}

const getFetchBadAssGuy = () => {
    if ("object" === typeof window) {
        return ("object" === typeof window.badAssGuys) && window.badAssGuys.badAssGuy //"6693614066929259" 
    } else {
        return ""
    }
}

export default class Client {
    constructor() {
        this.secret = {
            key: '8181818181818181',
            iv: '8181818181818181',
        }

        this.dynamicSecret = {
            key: () => getFetchBadAssGuy(),
            iv: () => getFetchBadAssGuy(),
        }
    }

    encrypt = (value) => {
        let secretkey = CryptoJS.enc.Utf8.parse(this.secret.key);
        let secretiv = CryptoJS.enc.Utf8.parse(this.secret.iv);
        let encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value), secretkey,
            {
                keySize: 128 / 8,
                iv: secretiv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            })

        return encrypted.toString();
    }

    decrypt = (value) => {
        let secretkey = CryptoJS.enc.Utf8.parse(this.secret.key);
        let secretiv = CryptoJS.enc.Utf8.parse(this.secret.iv);

        let decrypted = CryptoJS.AES.decrypt(value, secretkey,
            {
                keySize: 128 / 8,
                iv: secretiv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            })

        return decrypted.toString(CryptoJS.enc.Utf8);
    }

    encryptWithDynamicKey = (value) => {
        let secretkey = CryptoJS.enc.Utf8.parse(this.dynamicSecret.key());
        let secretiv = CryptoJS.enc.Utf8.parse(this.dynamicSecret.iv());
        let encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value), secretkey,
            {
                keySize: 128 / 8,
                iv: secretiv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            })

        return encrypted.toString();
    }

    decryptWithDynamicKey = (value) => {
        let secretkey = CryptoJS.enc.Utf8.parse(this.dynamicSecret.key());
        let secretiv = CryptoJS.enc.Utf8.parse(this.dynamicSecret.iv());

        let decrypted = CryptoJS.AES.decrypt(value, secretkey,
            {
                keySize: 128 / 8,
                iv: secretiv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            })

        return decrypted.toString(CryptoJS.enc.Utf8);
    }

    encryptBody = (body) => {
        // body = JSON.stringify(body); commented over json stringfying
        let encryptedBody = this.encryptWithDynamicKey(body);
        return encryptedBody;
    }

    decryptBody = (body) => {
        body = this.decryptWithDynamicKey(body);
        let decryptedBody = JSON.parse(body);
        return decryptedBody;
    }

}