import Image from "next/image"
import Link from "next/link"

const Branding = () => {
  return (
    <div>
      <Link href="/" title="Motilal Oswal Asset Management Company" className="tw-relative tw-block tw-h-[52px] tw-w-[141px]">
        <Image
          src={"/assets/images/footer/mo-amc-logo.svg"}
          alt="Motilal Oswal Asset Management Company"
          fill
          className="tw-object-contain"
        />
      </Link>
      <h6 className="tw-text-[15px] tw-leading-6 md:tw-text-base md:tw-leading-5 tw-font-semibold tw-mt-3 md:tw-mt-4">Motilal Oswal Asset Management Company</h6>
    </div>
  )
}

export default Branding