import React from 'react';

import renderHTML from 'react-render-html';
const AboutPara = (props) => {
    console.log(props,'props.class')
    
    return (
        <p className={`paraRegular ${props.class}`} style={{ ...props.style }}>
            {renderHTML(props.title)}
           
            </p>
    )
}
export default AboutPara;