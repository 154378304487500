import React, { useState, useEffect } from "react";
import BannerNEW from "../components/molecules/BannerTitle";
import Heading from "../components/atom/heading/h1";
import Bodywrapper from "../components/atom/container/container";
import Container from "../components/atom/container/index";
import Para from "../components/atom/paragraph/aboutpara";
import Section from "../components/atom/section";
import Image from "../components/atom/image";
import Prelogin from "../services/preLoginApi";
import HeadingH2 from "../components/atom/heading/h2";
import NextImage from "../components/atom/nextImage";

let api = new Prelogin();
function Aif(props) {
  const [state, setState] = useState("");
  console.log(props.res, "props.res");

  // const throwSomeError = () => { 
  //     console.log('Sample Error', thisVariableNotDefined);
  // }

  let { section_1, section_2, section_3, section_4, Banner, BannerMobile } =
    props?.res;

  console.log(section_1, "section_1");
  console.log(section_3, "section_3");
  console.log(state, "state");
  return (
    <Bodywrapper class="aifhome-page p-0">
      <BannerNEW
        class="color_white"
        title="Alternate Investment Funds"
        image={Banner}
      />
      <Container>
        <div className="row" style={{ marginTop: "40px" }}>
          {section_1.length > 0 ? (
            <React.Fragment>
              <React.Fragment>
                <div className="col-md-12">
                  <Para
                    title={section_1[0].desc1 !== "" ? section_1[0].desc1 : ""}
                  />

                  <Para
                    title={section_1[0].desc2 !== "" ? section_1[0].desc2 : ""}
                  />
                </div>
              </React.Fragment>
            </React.Fragment>
          ) : null}
        </div>

        {section_1.length >= 2 ? (
          <div className="row">
            {section_1[1].desc2 !== "" ? (
              <div className="colBlock clearfix">
                <div className="col_Img">
                  <div className="imgLayerBorder"></div>
                  <Image image={section_1[1].image} fullPath={true} />
                </div>
                <div className="colImgDesc">{section_1[1].desc2}</div>
              </div>
            ) : null}
          </div>
        ) : null}
        {/* <div className="row">
          <div className="colBlock clearfix">
            <div className="col_Img">
            <div className="imgLayerBorder"></div>
               <Image image="/assets/images/international/mens.jpg" fullPath={true} />
            </div>
            <div className="colImgDesc">The Portfolio Manager, Manish Sonthalia is also the Manager of the onshore Multi-Cap Strategy &ndash; NTDOP since inception (December 11, 2007)."
</div>
          </div>
        </div> */}

        {/*  */}
      </Container>
      {section_2.length > 0 ? (
        <section className="interTesti">
          <Container>
            <div className="row">
              <div className="col-md-12">
                <p className="testino_inter">{section_2[0].title}</p>
              </div>
            </div>
          </Container>
        </section>
      ) : null}
      {/* <section className="interTesti">
      <Container>
                        <div className="row">
                            <div className="col-md-12">
                                <p className="testino_inter">The Motilal Oswal India Fund (MOIF) seeks to invest in high-quality companies in India with significant growth potential, over a sustained period of time. We stay fully invested in equities.</p></div>
                        </div>
                    </Container>
          </section> */}
      <Container>
        <div className="row">
          <div className="col-md-12 text-center">
            <HeadingH2 title={section_3[0].title} />
            {/* <Heading title="Key Characteristics of our investing style" /> */}
          </div>
          <div className="col-md-12">
            <ul className="keyChar">
              {section_3[0].SubSection.length > 0 ? (
                <React.Fragment>
                  {section_3[0].SubSection.map((curr, index) => {
                    return (
                      <li key={index}>
                        <Image image={curr.image} fullPath={true} />
                        {/* <NextImage image={curr.image} height={150} width={150}/> */}

                        <p>{curr.title}</p>
                      </li>
                    );
                  })}
                </React.Fragment>
              ) : null}

              {/* <li>
                                <Image image="/assets/images/international/key2.png" />
                                <p>Concentrated portfolio<br />(15 – 25 Stocks)</p>
                            </li>
                            <li>
                                <Image image="/assets/images/international/key3.png" />
                                <p>Minimal turnover<br />(Less than 20%)</p>
                            </li> */}
            </ul>
          </div>
        </div>
      </Container>
      <Section class="secPadd50">
        <Container>
          <div className="row">
            {section_4.length > 0 ? (
              <React.Fragment>
                <div className="col-md-6">
                  <Para class="cusCMS" title={section_4[0].desc1} />
                  {/* <Para title="Motilal Oswal Asset Management is a part of the Motilal Oswal Group, co-founded in 1987 by Raamdeo Agrawal and Motilal Oswal. A diversified financial services organization with over USD 2.5 Bn (February 28, 2018) " /> */}
                </div>
                <div className="col-md-6">
                  <Para class="cusCMS" title={section_4[0].desc2} />
                  {/* <Para title="Raamdeo has been investing in India for over 37 years and is well known for the ‘Motilal Oswal Annual Wealth Creation Study’, a study he has been authoring over the last 22 years (since 1996)." /> */}
                </div>
              </React.Fragment>
            ) : null}
          </div>
          {/* <div className="row">
                       
                            <React.Fragment>
                                <div className="col-md-6">
                                    <Para title="Motilal Oswal Asset Management is a long only India equity manager, backed by Raamdeo Agrawal, widely recognized as one of India&rsquo;s best investing minds. The firm manages over USD 5.6 Bn in AUM (May 31, 2018), across managed accounts and co-mingled funds, with a stellar track record since 2003. Motilal Oswal Asset Management is a part of the Motilal Oswal Group, co-founded in 1987 by Raamdeo Agrawal and Motilal Oswal. A diversified financial services organization with over USD 2.5 Bn (February 28, 2018)" />
                                    {/* <Para title="Motilal Oswal Asset Management is a part of the Motilal Oswal Group, co-founded in 1987 by Raamdeo Agrawal and Motilal Oswal. A diversified financial services organization with over USD 2.5 Bn (February 28, 2018) " /> 
                                </div>
                                <div className="col-md-6">
                                    <Para title="financial services organization with over USD 2.5 Bn (February 28, 2018) in market capitalization, the firm has earned a reputation as one of the most trusted names in India and is well recognized by global institutional investors for its strong equity and research prowess. Raamdeo has been investing in India for over 37 years and is well known for the &lsquo;Motilal Oswal Annual Wealth Creation Study&rsquo;, a study he has been authoring over the last 22 years (since 1996)." />
                                    {/* <Para title="Raamdeo has been investing in India for over 37 years and is well known for the ‘Motilal Oswal Annual Wealth Creation Study’, a study he has been authoring over the last 22 years (since 1996)." />
                                </div>
                            </React.Fragment> 
                        

                    </div> */}
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="divider_n"></div>
              <h4 className="websiteINfo">
                Contact your Financial Advisor to know more or write back to us
                at <span>amc@motilaloswal.com</span>
              </h4>
            </div>
          </div>

          {/* <div>
            <button onClick={throwSomeError}>Throw Error</button>
          </div> */}
        </Container>
      </Section>
    </Bodywrapper>
  );
}

export async function getStaticProps() {
  let Alldata = {
    Banner: '',
    BannerMobile: '',
    section_1: [],
    section_2: [],
    section_3: [],
    section_4: []
  };
  let payload = { api_name: "AIFDetails" };
  let res = await api.getAIFDetails(payload);
  console.log(res, "AIFDetailssujeet 12345");
  if (res.data.success) {
    Alldata = res.data.data;
  }
  return {
    props: {
      res: Alldata,
    }
  };

}

// Aif.getInitialProps = async (ctx) => {
//   let Alldata = {
//     Banner: '',
//     BannerMobile: '',
//     section_1: [],
//     section_2: [],
//     section_3: [],
//     section_4: []
//   };
//   let payload = { api_name: "AIFDetails" };
//   let res = await api.getAIFDetails(payload);
//   console.log(res, "AIFDetailssujeet");
//   if (res.data.success) {
//     Alldata = res.data.data;
//   }
//   return {
//     res: Alldata,
//   };
// };

export default Aif;
