import React from 'react';
import Image from 'next/image';


const Avatar = (props) => {
    // let src = "";
    // if (props.fullPath) {
    //     src = props.image
    // } else {
    //     src = require(`../../../assets/images/${props.image}`).default
    // }
    return (
        <img src={props.image}   className={`${props.class}`} 
         style={{...props.style}} alt={props.alt || ''}
         aria-labelledby={props.ariaLabelledBy || ''}
        />
       
    );
};
export default Avatar

