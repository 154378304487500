import Image from "next/image"
import Link from "next/link"
import { distributorServices } from "./constants"

const DistributorServicesPopup = () => {
  return (
    <div className="menu-container tw-fixed tw-left-0 tw-top-[var(--top-for-menu-popup)] tw-w-full tw-z-[1000]">
      <div className='menu-popup container tw-flex tw-overflow-hidden tw-p-0'>
        <div className='tw-py-8 tw-px-[100px] tw-bg-white tw-w-full'>
          <div className="tw-grid tw-grid-cols-3 tw-w-full tw-gap-y-4 tw-gap-x-12">
            {distributorServices.map((item, i) =>
              <div key={i} className="tw-flex tw-items-center tw-gap-4 tw-py-4">
                <Image src={item.image} alt={item.title} width={32} height={32} />
                <Link key={i} href={item.link} title={item.title} className="hover:tw-text-black tw-text-base tw-w-fit tw-font-medium" target={item.external ? "_blank" : "_self"}>{item.title}</Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DistributorServicesPopup