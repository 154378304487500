import { useMediaQuery } from '@material-ui/core'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

const WhatsappPromoHeader = () => {
  const isMobile = useMediaQuery('(max-width:990px)')
  return (
    <div className='whatsappHeaderPromo'>
      <div className='whatsappWrapper'>
        {
          isMobile
            ? <Image src="/assets/images/header/whatsapp_banner_headline_mobile.gif" alt="Message gif" className='animated-text' height={104} width={820} />
            : <Image src="/assets/images/header/whatsapp_banner_headline_desktop.gif" alt="Message gif" className='animated-text' height={104} width={820} />
        }
        <Link href="https://wa.me/7304921822" className='whatsapp-cta'>
          <Image src="/assets/images/header/whatsapp-icon.gif" alt="Whatsapp icon gif" height={40} width={40} />
          <span>73049 21822</span>
        </Link>
      </div>
    </div>
  )
}

export default WhatsappPromoHeader
