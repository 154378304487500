import React, { memo } from "react";
import Image from "../../atom/image";

import HeadingH1 from "../../atom/heading/h1";
import Body from "../../atom/container/conatinerfull";

const BannerTitle = (props) => {
  return (
    <Body class="padd0">
      <div className="relative">
        <Image image={props.image} class={props.imgClass} alt={props.alt} style={{ height: 'auto', width: '100%'}}/>
        <div className="col-md-12 bannerHeading text-center">
          <HeadingH1 title={props.title} class={"color_white"} />
        </div>
        <div className="col-md-12 padd0 text-center">
          {/* <TabNav data={tabLink} active={active} /> */}
        </div>
      </div>
    </Body>
  );
};
export default BannerTitle;
