import Api from '.';
import { endpoints } from './endpoints';

export default class PreLoginApi extends Api {
    Latest_NAV(data) {
        let dd=endpoints.cms.Latest_NAV;
        window.open(dd, "_blank")
        console.log(endpoints.cms.Latest_NAV,'endpoints.cms.Latest_NAV')
        // let url = this.buildUrl(endpoints.cms.Latest_NAV, "full")
        // return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    Historical_NAV(data) {
        let dd= endpoints.cms.Historical_NAV;
         window.open(dd, "_blank")
        // let url = this.buildUrl(endpoints.cms.Historical_NAV, "full")
        // return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    Historical_Dividend(data) {
        let dd= endpoints.cms.Historical_Dividend;
        window.open(dd, "_blank")
        // let url = this.buildUrl(endpoints.cms.Historical_Dividend, "full")
        // return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    TER_Latest(data) {
        let dd= endpoints.cms.TER_Latest;
        window.open(dd, "_blank")
        // let url = this.buildUrl(endpoints.cms.TER_Latest, "full")
        // return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    TER_History(data) {
        let dd= endpoints.cms.TER_History;
        window.open(dd, "_blank")
        // let url = this.buildUrl(endpoints.cms.TER_History, "full")
        // return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    aboutUs(data) {
        let url = this.buildUrl(endpoints.preLogin.aboutUs, "full")
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    moviindex(data) {
        let url = this.buildUrl(endpoints.preLogin.moviindex, "full")
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    GetMoviIndexExcel(data){
        let url = this.buildUrl(endpoints.preLogin.GetMoviIndexExcel, "full")
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    LargeCapAlphaIndex(data) {
        let url = this.buildUrl(endpoints.preLogin.LargeCapAlphaIndex, "full")
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    InterBusiness(data) {
        // console.log("In generateOtp", data)
        let url = this.buildUrl(endpoints.preLogin.internationalBusiness, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    getAIFDetails(data) {
        // console.log("In generateOtp", data)
        let url = this.buildUrl(endpoints.preLogin.getAIFDetails, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    getFaq(data) {
        // console.log("In generateOtp", data)
        let url = this.buildUrl(endpoints.preLogin.faq, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    NavCall(data) {
        // console.log("In generateOtp", data)


        let url = this.buildUrl(endpoints.preLogin.NavDetail, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    NavHistoricalfilter(data) {
        // console.log("In generateOtp", data)


        let url = this.buildUrl(endpoints.preLogin.HistoricalFilter, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    MFhome(data) {
        // console.log("In generateOtp", data)


        let url = this.buildUrl(endpoints.preLogin.MFHome, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response).catch(e=>{
            console.log(e)
        });
    }
    Category(data) {
        // console.log("In generateOtp", data)


        let url = this.buildUrl(endpoints.preLogin.FundCategory, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    CategoryNew(data) {
        // console.log("In generateOtp", data)


        let url = this.buildUrl(endpoints.preLogin.FundCategoryNew, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }

    getMFfilter(data) {

        let url = this.buildUrl(endpoints.preLogin.GetFundsForFilter, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }

    fundDetail(data) {
       
        let url = this.buildUrl(endpoints.preLogin.fundDetail, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    CompareFunds(data) {
       
        let url = this.buildUrl(endpoints.preLogin.CompareFunds, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    passiveHome(data) {
     
        let url = this.buildUrl(endpoints.preLogin.passiveHome, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    HomePage(data) {
        let url = this.buildUrl(endpoints.preLogin.HomePage, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    download(data) {

        let url = this.buildUrl(endpoints.preLogin.download, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    SearchDownloads(data) {
        let url = this.buildUrl(endpoints.preLogin.SearchDownloads, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    ContactUs(data) {
        let url = this.buildUrl(endpoints.preLogin.ContactUs, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    enquiry(data) {
        let url = this.buildUrl(endpoints.preLogin.enquiry, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    perforamceGraph(data) {
        let url = this.buildUrl(endpoints.preLogin.perforamceGraph, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    perforamceGraphNew(data) {
        let url = this.buildUrl(endpoints.preLogin.perforamceGraphNew, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }

    STPCategory(data) {
        // console.log("In generateOtp", data)
        let url = this.buildUrl(endpoints.preLogin.STPCategory, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    PMSCategory(data) {
        // console.log("In generateOtp", data)
        let url = this.buildUrl(endpoints.preLogin.PMSCategory, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    PMSGraphData(data) {
        // console.log("In generateOtp", data)
        let url = this.buildUrl(endpoints.preLogin.PMSGraphData, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    PMSFundDetail(data) {
        // console.log("In generateOtp", data)
        let url = this.buildUrl(endpoints.preLogin.PMSFundDetail, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    getSchemesForSEO(data) {
        // console.log("In generateOtp", data)
        let payloadData = {
            "api_name": "getSchemesForSEO"
        }
        let url = this.buildUrl(endpoints.preLogin.getSchemesForSEO, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(payloadData)).then(response => response);
    }
    getTotalExpratio(data) {
        let payloadData = {
            "api_name": "TotalExpratio"
        }
        let url = this.buildUrl(endpoints.preLogin.getTotalExpratio, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(payloadData)).then(response => response);
    }
    PMSHomePage(data) {
        let url = this.buildUrl(endpoints.preLogin.PMSHomePage, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    saveNewsLetterInfo(data) {
        let url = this.buildUrl(endpoints.preLogin.saveNewsLetterInfo, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }

    // etf funds
    getEODUpdate(data){
        let url = this.buildUrl(endpoints.preLogin.ETFApi, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    getMarketUpdate(data){
        let url = this.buildUrl(endpoints.preLogin.ETFApi, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    getINavAndPrice(data){
        let url = this.buildUrl(endpoints.preLogin.ETFApi, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    getETFFundCategory(data){
        let url = this.buildUrl(endpoints.preLogin.ETFFundCategory, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }  
    SEODetails(data) {
        let url = this.buildUrl(endpoints.preLogin.SEODetails, "full");
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(
            (response) => response
        );
    }  
}
