import React from 'react';
import Image from 'next/image';


const NextImage = (props) => {
    // width, height will be read in optional. And required for remote images. And not required for local images.
    const { image, className, style = {}, alt = '', ...optional } = props; 
    return (
        <Image
            src={image}
            className={className}
            style={{ ...style }}
            alt={alt}
            {...optional}
        />

    );
};

export default NextImage;
