/* LOCAL AND DXTECH SERVER CONFIG */
/* MO SERVER CONFIG */
//  # API_BASE_URL_V2 = http://192.168.47.113
// API_BASE_URL_V2 = http://122.184.137.14
// CRYPTO = on
// # NEXT_PUBLIC_APIURL = https://mf.moamc.com/api/
// NEXT_PUBLIC_APIURL = http://dxtechsol.com:3000/api/
// # NEXT_PUBLIC_MFAPIURL = https://mf.moamc.com/mutualfund/api/v1/
// NEXT_PUBLIC_MFAPIURL = http://dxtechsol.com:8080/api/v1/
// # NEXT_PUBLIC_BASEURL = http://192.168.47.113/
// NEXT_PUBLIC_BASEURL = http://122.184.137.14/
// # NEXT_PUBLIC_MID = https://127.0.0.1/mutualfund/api/v1/
// NEXT_PUBLIC_MID = http://dxtechsol.com:8080/api/v1/

let local = process.env.NEXT_PUBLIC_APIURL;
let baseAPI = process.env.NEXT_PUBLIC_MFAPIURL;
let cmsApi = process.env.NEXT_PUBLIC_CMSURL; // "https://mf.moamc.com/CMS/cms/";
let BaseURL = process.env.NEXT_PUBLIC_BASEURL;
let BaseMiddlewear = process.env.NEXT_PUBLIC_MID;
let baseAPI2 = baseAPI;

// let local ="https://mf.moamc.com/api/";
// let baseAPI = "https://mf.moamc.com/mutualfund/api/v1/";
// let cmsApi="https://mf.moamc.com/CMS/api/v1/";
// let baseAPI2 = "http://dxtechsol.com:8080/api/v1/"
// let BaseURL = "http://122.184.137.14/";
// let BaseMiddlewear ="https://127.0.0.1/mutualfund/api/v1/";

export const endpoints = {
    baseUrl:baseAPI, //process.env.REACT_APP_BASE_URL,
    app: {
        //init: `${baseAPI}init`
        init:`${BaseURL}initApi/api/Init`,//BaseURL
    },
    user: {
        getUser: '/users'
    },
    login: {
        generateOtp: '/login/generateOtp',
        verifyOtp: '/login/authenticateUser',
        logout: '/login/logout',
        common: `${baseAPI}login/common`,
        kycCheck:`${local}kyc`,
        generateOTP:`${local}otp`,
        clientType:`${local}clientType`,
        authenticateUser:`${local}login`
    },
    additionalPurchase: {
        getFolioList: '/additionalPurchase/folioList',
    },
    preLogin: {
        internationalBusiness: `${baseAPI}getIntBusiness`,
        getAIFDetails: `${baseAPI}getAIFDetails`,
        aboutUs: `${baseAPI}Aboutus`,
        faq: `${baseAPI}getFAQ`,
        NavDetail: `${baseAPI}NAVDetails`,
        HistoricalFilter: `${baseAPI}HistoricalFilter`,
        MFHome: `${baseAPI}MFHome`,
        passiveHome: `${baseAPI}PassiveFundHome`,
        HomePage:`${baseAPI}HomePage`,
        fundDetail: `${baseAPI}FundDetail`,
        FundCategory: `${baseAPI}FundCategory`,
        FundCategoryNew: `/mutualfund/api/v1/FundCategoryNew`,
        STPCategory: `${baseAPI}SchemesForSTP`,
        GetFundsForFilter: `${baseAPI}GetFundsForFilter`,
        CompareFunds: `${baseAPI}CompareFunds`,
        download: `${baseAPI}getDownloads`,
        SearchDownloads: `${baseAPI}SearchDownloads`,
        ContactUs: `${baseAPI}ContactUs`,
        enquiry: `${baseAPI}enquiry`,
        perforamceGraph: `${baseAPI}PerformanceGraph`,
        perforamceGraphNew: `/mutualfund/api/v1/PerformanceGraphNew`,
        PMSCategory: `${baseAPI}PMSFundCategory`,
        PMSGraphData: `${baseAPI}PMSGraphData`,
        PMSFundDetail: `${baseAPI}PMSFundDetail`,
        getSchemesForSEO: `${baseAPI}getSchemesForSEO`,
        getTotalExpratio: `${baseAPI}getTotalExpratio`,
        PMSHomePage: `${baseAPI}PMSHomePage`,
        saveNewsLetterInfo:`${baseAPI}saveNewsLetterInfo`,
        LargeCapAlphaIndex:`${baseAPI}getLargeCapAlphaIndex`,
        ETFApi:`/mutualfund/api/v1/someFunc`,
        ETFFundCategory: `/mutualfund/api/v1/ETFFundCategory`,
        moviindex: `${baseAPI2}moviIndex`,
        GetMoviIndexExcel:`${baseAPI2}GetMoviIndexExcel`,
        SEODetails: `${baseAPI}SEODetails`,
    },
   

    ob: {
        masters: `${baseAPI}ob/getMasters`,
        common: `${baseAPI}ob/common`,
        ifscBankDet: `${baseAPI}ob/getBankFromIFSC`
    },
    trans: {
        common: `${baseAPI}trans/common`,
        commonGet: `${local}user`,
        foliolist:`${local}foliolist`,
        kycCheck:`${local}kyc`,
        CartDetails:`${local}CartDetails`
        
    },
    extra: {
        GetSchemeDetForSIPDashboard: `${baseAPI}/GetSchemeDetForSIPDashboard`,
        getSchemeName: `${baseAPI}/getSchemeName`,
    },
    cms:{
        Latest_NAV:`${cmsApi}GetNAVExcel/Latest_NAV`,
        Historical_NAV:`${cmsApi}GetNAVExcel/Historical_NAV`,
        Historical_Dividend:`${cmsApi}GetNAVExcel/Historical_Dividend`,
        TER_Latest:`${cmsApi}GetTERExcel/TER_Latest`,
        TER_History:`${cmsApi}GetTERExcel/TER_History`,
    },
    checkCookies: `/mutualfund/api/v1/chklogin`,

}

// GetNAVExcel