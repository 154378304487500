
import "core-js/stable";
import "regenerator-runtime/runtime";
import React from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import Head from 'next/head';
import AppApi from "../services/appApi";
import { useRouter } from 'next/router'
import 'bootstrap/dist/css/bootstrap.min.css';
import '/assets/css/fonts.css';
import '../styles/styles.css';
import '../styles/contact.css';
import PreLoginApi from "../services/preLoginApi";
import useSeo from "../components/hooks/useSeo";

let api = new PreLoginApi();
function MyApp({ Component, pageProps, footerData }) {
  const router = useRouter();
  const { title, pdesc, sdesc, keyData } = useSeo(footerData);

  let { query } = router;

  console.log(pageProps, 'pageProps_app', router);

  let headerFlag = false;
  if (Object.keys(query).length > 0) {
    if (query.hasOwnProperty("view")) {
      let getVal = query["view"];
      if (getVal === "app") {
        headerFlag = true;
      }
    }
  }
  console.log(query, "==query {view: 'app'}");

  return (
    <React.Fragment>
      <Head>
        <meta charSet="utf-8" />

        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="minimum-scale=1 width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="description" content={pdesc} />
        <meta name="keywords" content={keyData} />

        <link rel="canonical" href="https://aif.motilaloswalmf.com/" />

        {/* <!-- Google Tag Manager --> */}
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-NPBHR7');`,
          }}
        >
        </script>
        {/* <!-- End Google Tag Manager --> */}


        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-61001832-1"></script>
        <script dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'UA-61001832-1');`,
        }}>
        </script>
        <title>{title}</title>

      </Head>
      {!headerFlag ? <Header /> : null}
      <Component {...pageProps} />
      {!headerFlag ? <Footer /> : null}
    </React.Fragment>
  )


}

MyApp.getInitialProps = async ({ Component, ctx }) => {
  try {
    let payload = {
      api_name: "SEODetails",
    };
    const footerRes = await api.SEODetails(payload); //await fetch('https://jsonplaceholder.typicode.com/todos');
    let footerData = {};
    footerData = footerRes;

    let pageProps = {};
    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    return { footerData, pageProps };
  } catch (e) {
    return { footerData: {}, pageProps: {} }
  }
};

export default MyApp;