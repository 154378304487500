import { endpoints } from "./endpoints";
import client from "../lib/crypto/client";
var CryptoJS = require("crypto-js");

const isCrypto = process.env.NEXT_PUBLIC_CRYPTO === "on" ? true : false;
const secureResponse = process.env.NEXT_PUBLIC_ENABLE_RESPONSE_ENCODING;

const getFetchBadAssSecure = () => {
  if ("object" === typeof window) {
    let getBadObj =
      "object" === typeof window.badAssGuys
        ? window.badAssGuys.badAssSecure
        : ""; // "6693614066929259"
    return getBadObj;
  } else {
    return "";
  }
};

const fetchHeader = {
  "Content-Type": "application/json",
  "User-Agent": "WEB/MultipleCampaign",
  "user-agent": "WEB/MultipleCampaign",
  UserAgent: "WEB/MultipleCampaign",
}; //"appId":data

const fetchHeaderFile = {
  "Content-Type": "application/json",
  mimeType: "multipart/form-data",
};

const getOption = ({ header, method, body, encryptBody, encryptBodyAlgo }) => {
  let getBadAssGuySecure = getFetchBadAssSecure(); // fetch the bad ass secure guy
  let opt = {
    method,
    body,
    headers: {
      ...header,
      "secure-response": !!secureResponse,
      // appid: globalVariable.getPropertyByName('appid'),
      meta: getBadAssGuySecure ? getBadAssGuySecure : undefined,
    },
  };

  if (body && encryptBody) {
    let encryptedBody = { body: encryptBodyAlgo(body) };
    opt.body = JSON.stringify(encryptedBody);
  } else {
    let encryptedBody = { body: body };
    opt.body = JSON.stringify(encryptedBody);
  }

  return opt;
};

export function encrypt(text, key) {
  const iv = CryptoJS.lib.WordArray.random(16);
  const encrypted = CryptoJS.AES.encrypt(text, CryptoJS.enc.Hex.parse(key), {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return (
    iv.toString(CryptoJS.enc.Hex) +
    ":" +
    encrypted.ciphertext.toString(CryptoJS.enc.Hex)
  );
}

export function decrypt(text, key) {
  const parts = text.split(":");
  const iv = CryptoJS.enc.Hex.parse(parts.shift());
  const encryptedText = CryptoJS.enc.Hex.parse(parts.join(":"));
  const decrypted = CryptoJS.AES.decrypt(
    { ciphertext: encryptedText },
    CryptoJS.enc.Hex.parse(key),
    { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
  );
  return decrypted.toString(CryptoJS.enc.Utf8);
}

export default class Api {
  constructor() {
    this.crypto = new client(); //crypto instance
  }
  encrypt = (data) => {
    let encryptedText = this.crypto.encryptBody(data);
    return encryptedText;
  };

  getDecryptedResponse = (res) => {
    const decryptedResponse = JSON.parse(
      decrypt(res.data, process.env.NEXT_PUBLIC_ENC_KEY)
    );
    return decryptedResponse;
  };

  fetch = (url, method, body) => {
    let opt = getOption({
      method,
      body,
      header: fetchHeader,
      encryptBody: false,
    });
    let isSecureResponse;
    let statusCode;

    return fetch(url, opt)
      .then((response) => {
        isSecureResponse = response.headers.get("secure-response");
        statusCode = response.status;
        return response.json();
      })
      .then((res) => {
        const response = !!isSecureResponse
          ? this.getDecryptedResponse(res)
          : res;
        return {
          ...response,
          statusCode,
        };
      });
  };

  fetchFile = (url, method, body) => {
    let opt = {
      method: method,
      headers: fetchHeaderFile,
      body: body,
      credentials: "same-origin",
    };

    return fetch(url, opt).then((response) => response.json());
  };

  fetchNormal = (url, method, body) => {
    process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0";
    let opt = {
      method: method,
      headers: {
        ...fetchHeader,
        "secure-response": !!secureResponse,
      },
      body: body,
      //credentials: 'same-origin'
    };

    let isSecureResponse;
    let statusCode;

    return fetch(url, opt)
      .then((response) => {
        isSecureResponse = response.headers.get("secure-response");
        statusCode = response.status;
        return response.json();
      })
      .then((res) => {
        const response = !!isSecureResponse
          ? this.getDecryptedResponse(res)
          : res;
        return {
          ...response,
          statusCode,
        };
      });
  };

  buildUrl = (path, urlType = "") => {
    if (urlType === "full") {
      return `${path}`;
    } else {
      return `${endpoints.baseUrl}${path}`;
    }
  };
}
